const paths = {
  // PARTNER PATH
  homePartner: "/home",
  settingPartner: "/setting",
  changePasswordPartner: "/setting/change_password",
  changeLanguagePartner: "/setting/change_language",
  myAccountPartner: "/setting/my_account",
  termsPartner: "/setting/terms_of_use",
  privacyPolicyPartner: "/setting/privacy_policy",
  //---banner partner
  calendarPartner: "/calendar",
  calendarPartnerDetail: "/calendar/detail",
  reportPartner: "/report",
  walletPartner: "/wallet",
  bankInfoPartner: "/wallet/bank_info",
  ruleWalletPartner: "/wallet/rule",
  selectBank: "/wallet/select_bank",
  taskPartner: "/task",
  taskDetailPartner: "/task/detail",
  statisticPartner: "/statistic",
  registerServicePartner: "/register_service",
  registerServiceDetailPartner: "/register_sevice/detail",
  registerSubServiceDetailPartner: "/register_sevice/detail/sub_service",
  notifyPartner: "/notification",
  notifyDetailPartner: "/notification/detail",
  aboutPartner: "/about",
  helpPartner: "/help",

  // USER PATH
  homeUser: "/home",
  //---setting
  settingUser: "/setting",
  myAccountUser: "/setting/my_account",
  changePasswordUser: "/setting/change_password",
  changeLanguageUser: "/setting/change_language",
  termsUser: "/setting/terms_of_use",
  privacyPolicyUser: "/setting/privacy_policy",

  //---banner
  calendarUser: "/calendar",
  calendarUserDetail: "user/calendar/detail",
  promotionUser: "/promotion",
  promotionDetailUser: "/promotion_detail/:id",
  familyInfoUser: "/family_info",
  addFamilyInfoUser: "/family_info/add",
  updateFamilyInfoUser: "/family_info/update",
  theJobsUser: "/jobs",
  jobDetailUser: "/job_detail/:job_id",
  freeCourseUser: "/free_course",
  healthKnowledgeUser: "/health_knowledge",
  notificationUser: "/notification",
  notificationUserDetail: "/notification/detail",
  statisticUser: "/statistic",
  aboutUser: "/about",
  helpUser: "/help",
  //booking
  booking: "/booking",
  bookingMap: "/booking/map",
  bookingType: "/booking/type",
  selectPayment: "/booking/payment",
  selectUser: "/booking/user",
  serviceConfirm: "/booking/confirm",
  transferInfo: "/booking/transfer_info",

  //---service
  listServiceByCategory: "/list_service/:category_id",
  serviceDetail: "/service_detail/:service_id",

  // PUBLIC PATH
  //user auth
  login: "/login",
  register: "/register",
  terms: "/terms_of_use",
  resetPassword: "/reset_password",
  createPassword: "/create_password",
  createPasswordPartner: "/create_password",
  //partner auth
  loginPartner: "/login",
  registerPartner: "/register",
};

export default paths;
