import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import { notification } from "antd";
// import { useTranslation } from "react-i18next";

import logo_bluecare from "../../../../assets/images/image_logo_full.png";
import icon_user from "../../../../assets/icons/icon_user.png";
import icon_password from "../../../../assets/icons/icon_password.png";
// import icon_facebook from "../../../../assets/icons/icon_facebook.png";
import icon_google from "../../../../assets/icons/icon_google.png";

import overLoader from "../../../../components/overLoader";
// import { FACEBOOK_APP_ID } from "../../../../utils/auth";
import { authApi } from "../../../../api/authApi";
import { useAppDispatch } from "../../../../redux/hook";
import { getInfoTasker } from "../../../../redux/Slices/partnerSlice";
import { setRole } from "../../../../redux/Slices/AuthSlice";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../../helper/localStorage";
import paths from "../../../../config";

export const LoginPartner = () => {
  // const { t } = useTranslation();
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [api, showPopup] = notification.useNotification();
  const { t, i18n } = useTranslation(["auth", "common", "setting"]);
  const languageLocal: any = getLocalStorage("language");

  useEffect(() => {
    i18n.changeLanguage(languageLocal || "vi");
  }, [i18n, languageLocal]);

  const alertSuccess = (message: string) => {
    api.success({
      message,
      placement: "topRight",
      duration: 2,
    });
  };
  const alertFail = (message: string) => {
    api.error({
      message,
      placement: "topRight",
      duration: 3,
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleLogin: any = async (data: any, caseLogin: number) => {
    setStatusLoader(true);
    localStorage.clear();
    if (caseLogin === 1) {
      /* LOGIN GOOGLE */
      try {
        const resData: any = await authApi.loginPartnerGoogle({ access_token: data?.access_token });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoTasker());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/partner/create_password?role=partner");
              navigate(`${paths.createPassword}?role=partner`);
            }, 1000);
          } else {
            dispatch(setRole({ role: "partner" }));
            alertSuccess(resData.message);
            setTimeout(() => {
              //   userInfo?.registed_service === 0 ? navigate("/partner/register_service") : navigate("/partner/home");
              userInfo?.registed_service === 0 ? navigate(paths.registerServicePartner) : navigate(paths.homePartner);
            }, 1000);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else if (caseLogin === 2) {
      /* LOGIN FACEBOOK */
      try {
        const resData: any = await authApi.loginPartnerFacebook({
          access_token: data?.accessToken,
          fb_id: data?.userID,
        });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoTasker());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/partner/create_password?role=partner");
              navigate(`${paths.createPassword}?role=partner`);
            }, 1000);
          } else {
            dispatch(setRole({ role: "partner" }));
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/partner/register_service");
              //   userInfo?.registed_service === 0 ? navigate("/partner/register_service") : navigate("/partner/home");
              userInfo?.registed_service === 0 ? navigate(paths.registerServicePartner) : navigate(paths.homePartner);
            }, 1000);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else if (caseLogin === 3) {
      /* LOGIN APPLE */
      try {
        const resData: any = await authApi.loginApple({
          code: data?.authorization?.code,
          name: "",
          email: "",
          role: "tasker",
        });
        if (resData.status === 1) {
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          const resultAction = await dispatch(getInfoTasker());
          const userInfo = resultAction.payload;
          if (userInfo.password_is_null) {
            alertSuccess(resData.message);
            setTimeout(() => {
              //   navigate("/partner/create_password?role=partner");
              navigate(`${paths.createPassword}?role=partner`);
            }, 1000);
          } else {
            alertSuccess(resData.message);
            dispatch(setRole({ role: "partner" }));
            setTimeout(() => {
              //   navigate("/partner/register_service");
              //   userInfo?.registed_service === 0 ? navigate("/partner/register_service") : navigate("/partner/home");
              userInfo?.registed_service === 0 ? navigate(paths.registerServicePartner) : navigate(paths.homePartner);
            }, 1000);
          }
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    } else {
      /* Login Basic */
      try {
        const resData: any = await authApi.loginPartner(data);
        if (resData.status === 1) {
          localStorage.clear();
          localStorage.setItem("access_token", JSON.stringify(resData.data.token));
          setStatusLoader(false);
          alertSuccess(resData.message);
          dispatch(setRole({ role: "partner" }));
          setTimeout(() => {
            // navigate("/partner/register_service");
            // resData.data.registed_service === 0 ? navigate("/partner/register_service") : navigate("/partner/home");
            resData.data.registed_service === 0 ? navigate(paths.registerServicePartner) : navigate(paths.homePartner);
          }, 1000);
        }
      } catch (err: any) {
        setStatusLoader(false);
        alertFail(err.response.data.message);
      }
    }
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => handleLogin(codeResponse, 1),
    onError: (error) => console.log("Login Failed: ", error),
  });

  return (
    <div className="wrapper login">
      {statusLoader ? overLoader() : ""}
      {showPopup}
      <div className="login container">
        <div className="login-logo">
          <img src={logo_bluecare} alt="" />
        </div>
        <h1 className="login-title">{t("dang_nhap_tai_khoan_doi_tac")}</h1>
        <div className="login-form">
          <form onSubmit={handleSubmit(handleLogin)} className="login-form-main">
            <div className="login-form-group">
              <input type="text" className="username" placeholder="Email" {...register("email", { required: true })} />
              <div className="icon">
                <img src={icon_user} alt="" />
              </div>
            </div>
            {/* <div className="label-require">{errors.email && <span>{t("common:yeu_cau_khong_de_trong")}</span>}</div> */}
            {errors.email && (
              <div className="label-require">
                <span>{t("common:yeu_cau_khong_de_trong")}</span>
              </div>
            )}
            <div className="login-form-group">
              <input
                type="password"
                className="password"
                placeholder={t("mat_khau")}
                {...register("password", { required: true })}
              />
              <div className="icon">
                <img src={icon_password} alt="" />
              </div>
            </div>
            {errors.password && (
              <div className="label-require">
                <span>{t("common:yeu_cau_khong_de_trong")}</span>
              </div>
            )}
            <div className="login-form-button">
              {/* <button className="button-register secondary no-highlight">Đăng ký</button> */}
              <Link
                //   to="/partner/register"
                to={paths.registerPartner}
                className="button-register auth-button secondary no-highlight"
              >
                {t("dang_ki")}
              </Link>
              <button type="submit" className="button-login auth-button primary no-highlight">
                {t("dang_nhap")}
              </button>
            </div>
          </form>
        </div>
        <div className="login-social">
          {/* <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name,email"
            cssClass="login-social-button no-highlight"
            typeButton="button"
            disableMobileRedirect={true}
            icon={
              <div className="login-social-icon">
                <img src={icon_facebook} alt="" />
              </div>
            }
            textButton="Đăng nhập bằng Facebook"
            callback={(data: any) => handleLogin(data, 2)}
          /> */}
          <button
            className="login-social-button no-highlight"
            onClick={() => {
              handleLoginGoogle();
            }}
          >
            <div className="login-social-icon">
              <img src={icon_google} alt="" />
            </div>
            <span className="login-social-text">{t("dang_nhap_voi_google")}</span>
          </button>
          <AppleSignin
            authOptions={{
              clientId: "com.bluecare.dev.fe.services",
              scope: "name email",
              redirectURI: "https://bluecare-dev-fe.moncow.com/login",
              usePopup: true,
            }}
            uiType="light"
            className="apple-auth-btn login-social-button"
            noDefaultStyle={false}
            buttonExtraChildren={t("dang_nhap_voi_apple")}
            // onSuccess={(response: any) => console.log("Apple response: ", response)}
            // onError={(error: any) => console.error(error)}
            onSuccess={(response: any) => handleLogin(response, 3)}
            onError={(error: any) => handleLogin(error, 3)}
          />
        </div>
        <div className="forgot-password">
          {/* <Link to="/user/reset_password" className="forgot-password-button no-highlight">
            {t("quen_mat_khau")}?
          </Link> */}
          <Link to={paths.resetPassword} className="forgot-password-button no-highlight">
            {t("quen_mat_khau")}?
          </Link>
        </div>
        <Link to="/terms_of_use" className="term-of-use no-highlight">
          {t("dieu_khoan_su_dung")}
        </Link>
      </div>
    </div>
  );
};
